import React from 'react';
import StaticPage from '../PageBuilder/StaticPage';
import { LayoutComposer } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import css from './LandingPage.module.css';
import { useEffect, useState } from 'react';
// sections
import TopbarSection from './TopBarSection/topbar';
import HeroCarousel from './carousels/HeroCarousel';
import Neighborhoods from './NeighborhoodsSection/Neighborhoods';
import { LandingPageMap } from './map/LandingPageMap';
import CommunityCarousel from './carousels/CommunityCarousel';
import TrendingProductsCarousel from './carousels/TrendingProductsCarousel';
import FooterSection from './FooterSection/Footer';
import { getTotalCommunityMember } from '../../util/api';


// // icons
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import TopbarContainer from '../TopbarContainer/TopbarContainer';


const LandingPage = () => {
  const config = useConfiguration();

  const layoutAreas = `
    topbar
    main
    footer
  `;
  const { logoImageDesktop, logoImageMobile, logoSettings } = config.branding;

  const [totalCommunityMember, setTotalCommunityMember] = useState([])
  useEffect(() => {
    const fetchCommunityMember = async () => {
      try {
        const response = await getTotalCommunityMember(JSON.stringify('empty request'));
        setTotalCommunityMember(response.data);
      } catch (error) {
        console.error('Error fetching the locations of stores: ', error);
      }
    };

    fetchCommunityMember();
  }, []);

  console.log("Deploy on ", "9 Oct 04:15")

  return (
    <StaticPage
      className={css.root}
      title="Locago Landing Page"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'web page',
        description: 'Description of this page',
        name: 'Locago Landing Page',
      }}
    >
      <LayoutComposer areas={layoutAreas}
        className={css.layout}>
        {props => {
          const { Topbar, Main, Footer, } = props;
          return (
            <>
              <TopbarContainer currentPage="LandingPage" />
              <Main>
                <HeroCarousel />
                <Neighborhoods />
                <section className={css.mapsection}>
                  <div className={css.mapcaption}>
                    <p className={css.maptext}>Find Local Businesses</p>
                    <button>
                      Register your shop
                    </button>
                  </div>
                  <div className={css.mapcontainer}>
                    <LandingPageMap />
                  </div>
                </section>
                <section className={css.carouselsection}>
                  <h2>Meet Your Community</h2>
                  <CommunityCarousel totalCommunityMember={totalCommunityMember} logoImageDesktop={logoImageDesktop} />                  <h2>Shop Featured Products</h2>
                  <TrendingProductsCarousel />
                </section>
              </Main>
              <Footer>
                <FooterSection />
              </Footer>
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export default LandingPage;
